import { gql } from "@apollo/client"

export const RECRUITMENT_PUBLICATIONCHANNELS = gql`
  query PublicationChannelsInfo($recruitmentId: String!) {
    publishChannels(orderBy: { sort: asc }) {
      id
      title
      description
      logo {
        id
        url
      }
      publishedDuration
      price
      offer
      sort
      selected(where: { recruitment: { id: { equals: $recruitmentId } } }) {
        id
        activated
        selectedOffer
      }
    }
  }
`
export const RECRUITMENT_ADMETER_QUERY = gql`
  query RecruitmentQuery($id: String!) {
    recruitment(where: { id: $id }) {
      id
      settings
      recruitmentSelectedPublishChannels {
        id
        activated
        publishChannel {
          id
          title
        }
      }
      publishChannelsOther
    }
  }
`
export const RECRUITMENTS_ADMETER_QUERY = gql`
  query RecruitmentsQuery {
    recruitments {
      id
      settings
      recruitmentSelectedPublishChannels {
        id
        activated
        publishChannel {
          id
          title
        }
      }
      publishChannelsOther
    }
  }
`
export const RECRUITMENT_ADD_CHANNELSELECTION = gql`
  mutation AddPublicationChannelSelection($channelId: String!, $recruitmentId: String!, $activate: Boolean!) {
    createRecruitmentSelectedPublishChannel(data: { activated: $activate, publishChannel: { connect: { id: $channelId } }, recruitment: { connect: { id: $recruitmentId } } }) {
      id
    }
  }
`
export const RECRUITMENT_ADMETER_UPDATE = gql`
  mutation UpdateRecruitmentSettings($settings: Json, $recruitmentId: String) {
    updateRecruitment(where: { id: $recruitmentId }, data: { settings: $settings }) {
      id
    }
  }
`

export const RECRUITMENT_REMOVE_CHANNELSELECTION = gql`
  mutation RemovePublicationChannelSelection($selectionId: String!) {
    deleteRecruitmentSelectedPublishChannel(where: { id: $selectionId }) {
      id
    }
  }
`

export const RECRUITMENT_UPDATE_OTHERCHANNEL = gql`
  mutation UpdateRecruitmentOtherPulishChannel($recruitmentId: String!, $commentText: String) {
    updateRecruitment(where: { id: { equals: $recruitmentId } }, data: { publishChannelsOther: { set: $commentText } }) {
      id
    }
  }
`

export const RECRUITMENT_SET_CHANNELACTIVESTATE = gql`
  mutation SetChannelActiveStatus($selectionId: String!, $activated: Boolean!) {
    updateRecruitmentSelectedPublishChannel(where: { id: $selectionId }, data: { activated: { set: $activated } }) {
      id
      activated
    }
  }
`

export const RECRUITMENT_TOGGLE_CHANNELOFFER = gql`
  mutation UpdateRecruitmentSelectedPublishChannel($selectionId: String!, $selectedOffer: Boolean!) {
    updateRecruitmentSelectedPublishChannel(where: { id: $selectionId }, data: { selectedOffer: { set: $selectedOffer } }) {
      id
      activated
    }
  }
`

export const RECRUITMENT_GET_PUBLISHEDDURATION = gql`
  query jobAdPublishDuration($recruitmentId: String!) {
    recruitment(where: { id: $recruitmentId }) {
      id
      title
      jobAd {
        id
        publishStart
        publishStop
      }
      company {
        id
        name
        careerPage
      }
    }
  }
`

export const RECRUITMENT_SET_PUBLISHEDDURATION = gql`
  mutation updateRecruitmentJobPublicationDuration($jobId: String!, $publishStart: DateTime, $publishStop: DateTime, $selectionId: String!, $activated: Boolean!) {
    updateJobAd(where: { id: $jobId }, data: { publishStart: { set: $publishStart }, publishStop: { set: $publishStop } }) {
      id
      publishStart
      publishStop
    }
    updateRecruitmentSelectedPublishChannel(where: { id: $selectionId }, data: { activated: { set: $activated } }) {
      id
      activated
    }
  }
`
