import React from 'react';

export default function withUpdateQueryOnBroadcast(updateOptions) {
  const mutationQueryMap = Object.keys(updateOptions).reduce((map, queryName) => {
    const value = updateOptions[queryName]
    if (!value) {
      return map
    }
    const mutationNames = Array.isArray(value) ? value : [value]
    for (let i = 0; i < mutationNames.length; i++) {
      const mutation = mutationNames[i];
      if ( !(mutation in map) ) {
        map[mutation] = [queryName]
      } else {
        map[mutation].push(queryName)
      }
    }
    return map
  }, {})

  return WrappedComponent => {
    return class extends React.PureComponent {
      constructor(props) {
        super(props)
        this.displayName = "UpdateQueryOnBroadcast"
      }

      componentDidMount = (prevProps, prevState) => {
        window.addEventListener("message", this.handleWindowMessage.bind(this))
      }

      componentWillUnmount() {
        window.removeEventListener("message", this.handleWindowMessage)
      }

      handleWindowMessage(event) {
        const { data : { operation, src } } = event

        if (!operation || !operation.operationName || !src) {
            return;
        }

        // Ignore messages from the same window
        if (src === window.location.pathname) return

        if (operation.operationName in mutationQueryMap) {
          const queries = mutationQueryMap[operation.operationName]
          for (let i = 0; i < queries.length; i++) {
            const queryName = queries[i];
            if ( !(queryName in this.props) ) {
              console.warn(`Apollo-query with name "${queryName}" was not found in props.`)
              continue
            }
            const query = this.props[queryName]
            if ( query.refetch ) {
              query.refetch()
            }
          }
        }
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}
