import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { compose } from "react-recompose"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { gql } from "@apollo/client"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import styled from "styled-components"
import { closeMainMenu, toggleMainMenu, setMenuActiveCount, setMenuDraftCount, setMenuClosedCount } from "_actions/menu-actions"
import { ADMIN_COMPANY, REC_STATS_ID } from "_root/constants"
import { RECRUITMENTS_COUNT, CLIENT_RECRUITMENTS_STATS } from "_containers/recruitment/recruitment-ql"
import CloseIcon from "_images/menu-close.svg"
import OpenIcon from "_images/menu-open.svg"
import PlusIcon from "_images/plus.svg"
import MinusIcon from "_images/minus.svg"
import inject from "_services/inject"
import * as url from "_routes/url-names"
import { SmallSpinner } from "_root/layout/form-elements"
import LoginService from "_services/login-service"

//#region Styles
const SideBar = styled.div`
  color: var(--color-text-white);
  background-color: var(--color-nav-2);
  transition: background-color 0.1s;
  z-index: 2;

  &.open {
    min-width: 260px;
    overflow-y: auto;
  }

  &:not(.open):hover {
    background-color: var(--color-nav-3);
  }

  @media screen and (max-height: 600px) {
    &.open {
      min-width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 0;
    z-index: 20000;

    &.open {
      width: 100%;
      max-width: 400px;
    }
  }
`
const MenuHead = styled.a`
  position: relative;
  display: flex;
  justify-content: ${props => (props.$open ? "flex-end" : "center")};
  align-items: center;
  height: ${props => (props.$open ? "auto" : "100%")};
  padding-right: ${props => (props.$open ? "20px" : "")};
  border-bottom: 1px solid var(--color-text-medium);
  color: var(--color-text-white);
  font-size: 0.9em;
  font-weight: 200;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`
const MenuClose = styled.i`
  display: flex;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url(${CloseIcon}) no-repeat scroll 50% 50% transparent;
  background-size: 40%;
`
const MenuOpen = styled.i`
  display: flex;
  align-self: flex-start;
  width: 50px;
  height: 50px;
  background: url(${OpenIcon}) no-repeat scroll 50% 50% transparent;
  background-size: 50%;
  border-bottom: 1px solid var(--color-line-dark);
  transition: background-color 0.1s;

  @media screen and (max-width: 767px) {
    width: 65px;
    height: 65px;
    border-bottom: none;
    background-size: 40%;
  }
`
const MenuShow = styled.span`
  position: absolute;
  justify-content: center;
  color: var(--color-text-white);
  font-weight: 200;
  white-space: nowrap;
  text-transform: uppercase;
  transform: rotate(-90deg);

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Triangle = styled.div`
  position: absolute;
  top: 50%;
  left: 75%;
  width: 20px;
  height: 20px;
  overflow: hidden;
  z-index: 1;
  transform: translate(0, -50%);
  transition: left 0.1s;

  ${SideBar}:hover & {
    left: 108%;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: -12px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    box-sizing: border-box;
    border: 1px solid var(--color-nav-2);
    background: transparent;
    transform: rotate(45deg);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Menu = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.level-1 {
    font-weight: 200;
  }
`
const SubMenu = styled(Menu)`
  padding: 0;

  &.level-2 {
    > li > a {
      padding-left: 40px;
      padding-right: 20px;
    }
  }
`
const MenuItem = styled.li`
  display: flex;
  flex-flow: column nowrap;

  .level-1 > & {
    border-bottom: 1px solid var(--color-text-medium);
  }

  @media screen and (min-width: 768px) {
    display: ${props => (props.$mobile ? "none" : "flex")};
  }
`
const MenuAnchor = styled.a`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 30px;
  font-size: 0.9em;
  color: var(--color-text-white);
  background: var(--color-nav-2);
  text-decoration: none;

  span {
    border-bottom: 2px solid transparent;
  }

  &.active {
    background: var(--color-nav-2-dark);
    font-weight: 400;

    i {
      background-color: var(--color-nav-3-dark);
    }
  }

  &:hover {
    text-decoration: none;
    background: var(--color-nav-2-dark);
  }

  > i {
    display: block;
    width: 50px;
    height: 50px;
    margin-left: 30px;
    background: url(${props => (props.$open ? MinusIcon : PlusIcon)}) no-repeat scroll 50% 50%;
    background-size: 35%;
    background-color: var(--color-nav-3);
  }
`
const MenuLink = styled(NavLink)`
  cursor: pointer;
  display: flex;
  font-size: 0.9em;
  align-items: center;
  height: 50px;
  color: var(--color-text-white);
  background-color: var(--color-nav-2);
  padding: 0 60px 0 30px;
  text-decoration: none;

  span {
    border-bottom: 2px solid transparent;
  }

  &.active {
    font-weight: 400;
  }

  &.active,
  &:hover {
    text-decoration: none;
    background-color: var(--color-nav-2-dark);
  }
`
const SubLink = styled(NavLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 0.9em;
  font-weight: 200;
  color: var(--color-text-white);
  text-decoration: none;
  outline: none;

  .active-count,
  .draft-count {
    display: inline-block;
    width: 35px;
    height: 25px;
    margin-left: auto;
    padding-top: 3px;
    border-radius: 12px;
    background-color: var(--color-brand-red-light);
    color: var(--color-text-white);
    text-align: center;
  }

  .draft-count {
    background-color: var(--color-bg-white);
    color: var(--color-text-dark);
  }

  &.active {
    span {
      font-weight: 400;
      padding-bottom: 3px;
      border-bottom: 4px solid var(--color-brand-red-light);
    }
  }

  &:hover {
    text-decoration: none;
    background: var(--color-white-hover);
  }
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user", "menu"),
  graphql(RECRUITMENTS_COUNT, {
    name: "recruitmentsCountQuery",
    options: ({ user }) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: { companyId: user.companyView.id },
    }),
  }),
  graphql(CLIENT_RECRUITMENTS_STATS, { name: "recuitmentStatsQuery", options: () => ({ fetchPolicy: "cache-only" }) }),
  connect(null, dispatch => bindActionCreators({ closeMainMenu, toggleMainMenu, setMenuActiveCount, setMenuDraftCount, setMenuClosedCount }, dispatch))
)
export default class SideMenu extends React.Component {
  constructor(props) {
    super(props)
    const pathIsRecruit = this.props.location.pathname.indexOf(url.REKRYTERING) > -1
    const pathIsRecruitActive = this.props.location.pathname.indexOf(url.REKRYTERING + url.REK_AKTIVA) > -1
    const pathIsRecruitDraft = this.props.location.pathname.indexOf(url.REKRYTERING + url.REK_UTKAST) > -1
    const pathIsRecruitClosed = this.props.location.pathname.indexOf(url.REKRYTERING + url.REK_AVSLUTADE) > -1
    const pathIsAdmin = this.props.location.pathname.indexOf(url.ADMIN) > -1
    const pathIsSettings = this.props.location.pathname.indexOf(url.INSTALLNINGAR) > -1
    const pathIsProfile = this.props.location.pathname.indexOf(url.INST_MY_PROFILE) > -1
    this.state = {
      mobile: window.innerWidth < 768,
      routeIsJobbet: props.match.params.company === ADMIN_COMPANY,
      routeIsRecruit: pathIsRecruit,
      routeIsRecruitActive: pathIsRecruitActive,
      routeIsRecruitDraft: pathIsRecruitDraft,
      routeIsRecruitClosed: pathIsRecruitClosed,
      routeIsAdmin: pathIsAdmin,
      routeIsSettings: pathIsSettings,
      routeIsProfile: pathIsProfile,
      routeShowRecruit: pathIsRecruit,
      routeShowAdmin: pathIsAdmin,
      routeShowSettings: pathIsSettings,
      routeShowProfile: pathIsProfile,
    }
  }

  componentDidMount = () => {
    this.mql = window.matchMedia("(max-width: 767px)")
    this.mql.addEventListener("change", this.onWidthChange)
  }

  componentWillUnmount = () => {
    this.mql.removeEventListener("change", this.onWidthChange)
  }

  componentDidUpdate = () => {
    const { recruitmentsCountQuery, recuitmentStatsQuery, client } = this.props
    const recruitmentStats = { ...recuitmentStatsQuery.recruitmentStats }
    if (recruitmentStats?.countRecruitmentsIsStale) {
      recruitmentStats.countRecruitmentsIsStale = false
      client.writeQuery({
        query: gql`
          query RecruitmentStats {
            recruitmentStats {
              countRecruitmentsIsStale
              activeRecruitmentsIsStale
              draftRecruitmentsIsStale
              closedRecruitmentsIsStale
            }
          }
        `,
        data: {
          recruitmentStats
        },
      })
      recruitmentsCountQuery.refetch()
    }
  }

  static getDerivedStateFromProps = (nextProps) => {
    const nextState = {}
    const { menu, recruitmentsCountQuery, setMenuActiveCount, setMenuDraftCount, setMenuClosedCount } = nextProps
    const { loading, recruitmentsCount } = recruitmentsCountQuery
    if (loading) return nextState
    if (menu.activeCount !== recruitmentsCount.activeCount) {
      setMenuActiveCount(recruitmentsCount.activeCount)
    }
    if (menu.draftCount !== recruitmentsCount.draftCount) {
      setMenuDraftCount(recruitmentsCount.draftCount)
    }
    if (menu.closedCount !== recruitmentsCount.closedCount) {
      setMenuClosedCount(recruitmentsCount.closedCount)
    }
    return nextState
  }

  onWidthChange = e => {
    this.setState({ mobile: e.currentTarget.matches })
    if (e.currentTarget.matches && this.props.menu.mainMenuOpen) {
      this.props.closeMainMenu()
    }
  }

  onRouteChange = () => {
    this.state.mobile && this.props.closeMainMenu()
  }

  onRouteRecruit = () => {
    const COMPANY = "/" + (this.props.match.params.company || this.props.user.companyView.urlName)
    let state = url.REK_AKTIVA
    if (this.state.routeIsRecruitDraft) state = url.REK_UTKAST
    else if (this.state.routeIsRecruitClosed) state = url.REK_AVSLUTADE
    this.props.navigate(COMPANY + url.REKRYTERING + state)
    // this.props.closeMainMenu()
  }

  routeAdmin = () => {
    this.setState({ routeShowAdmin: !this.state.routeShowAdmin })
  }

  routeSettings = () => {
    this.setState({ routeShowSettings: !this.state.routeShowSettings })
  }

  routeProfile = () => {
    this.setState({ routeShowProfile: !this.state.routeShowProfile })
  }

  logout = e => {
    e.preventDefault()
    LoginService.clearToken({ client: this.props.client, reload: true })
  }

  render() {
    const { user, menu, match } = this.props
    const { mobile } = this.state

    if (!menu.mainMenuOpen) {
      return (
        <SideBar $isJobbet={user.companyView.isJobbet} $isGroup={user.companyView.group}>
          <MenuHead onClick={this.props.toggleMainMenu}>
            <Triangle />
            <MenuOpen />
            <MenuShow>Visa meny</MenuShow>
          </MenuHead>
        </SideBar>
      )
    }

    const COMPANY = "/" + (match.params.company || user.companyView.urlName)
    const activeBadge = Number.isInteger(menu.activeCount) ? menu.activeCount : <SmallSpinner />
    const draftBadge = Number.isInteger(menu.draftCount) ? menu.draftCount : <SmallSpinner />

    return (
      <SideBar $isJobbet={user.companyView.isJobbet} $isGroup={user.companyView.group} className="open">
        <MenuHead $open onClick={this.props.toggleMainMenu}>
          <MenuClose />
          Dölj meny
        </MenuHead>
        <div className="menu-wrapper">
          <Menu className="level-1">
            <MenuItem>
              <MenuAnchor $open={this.state.routeShowRecruit} onClick={this.onRouteRecruit} className={this.state.routeIsRecruit ? "active" : ""}>
                <span>Rekryteringsöversikt</span>
              </MenuAnchor>
              {this.state.routeShowRecruit && (
                <SubMenu className="level-2">
                  <MenuItem>
                    <SubLink to={COMPANY + url.REKRYTERING + url.REK_AKTIVA} onClick={this.onRouteChange}>
                      <span className="active-recruits">Aktiva</span>
                      <div className="active-count">{activeBadge}</div>
                    </SubLink>
                  </MenuItem>
                  <MenuItem>
                    <SubLink to={COMPANY + url.REKRYTERING + url.REK_UTKAST} onClick={this.onRouteChange}>
                      <span className="recruits-draft">Utkast</span>
                      <div className="draft-count">{draftBadge}</div>
                    </SubLink>
                  </MenuItem>
                  <MenuItem>
                    <SubLink to={COMPANY + url.REKRYTERING + url.REK_AVSLUTADE} onClick={this.onRouteChange}>
                      <span>Avslutade</span>
                    </SubLink>
                  </MenuItem>
                </SubMenu>
              )}
            </MenuItem>
            {user.superAdmin && (
              <MenuItem>
                <MenuLink to={COMPANY + url.KANDIDAT} onClick={this.onRouteChange}>
                  <span>Kandidatbanken</span>
                </MenuLink>
              </MenuItem>
            )}
            {!user.companyView.group && !mobile && (
              <MenuItem>
                <MenuLink to={COMPANY + url.INTERVIEW} onClick={this.onRouteChange}>
                  <span>Tidsbokning</span>
                </MenuLink>
              </MenuItem>
            )}
            {user.superAdmin && (
              <MenuItem>
                <MenuAnchor $open={this.state.routeShowAdmin} onClick={this.routeAdmin} className={this.state.routeShowAdmin ? "active" : ""}>
                  <span>Admin</span>
                  <i />
                </MenuAnchor>
                {this.state.routeShowAdmin && (
                  <SubMenu className="level-2">
                    <MenuItem>
                      <SubLink to={COMPANY + url.ADMIN + url.ADMIN_COMPANIES} onClick={this.onRouteChange}>
                        <span>Företag</span>
                      </SubLink>
                    </MenuItem>
                    <MenuItem>
                      <SubLink to={COMPANY + url.ADMIN + url.ADMIN_NEW_COMPANY} onClick={this.onRouteChange}>
                        <span>Nytt företag</span>
                      </SubLink>
                    </MenuItem>
                    <MenuItem>
                      <SubLink to={COMPANY + url.ADMIN + url.ADMIN_GROUPS} onClick={this.onRouteChange}>
                        <span>Grupper</span>
                      </SubLink>
                    </MenuItem>
                    <MenuItem>
                      <SubLink to={COMPANY + url.ADMIN + url.ADMIN_NEW_GROUP} onClick={this.onRouteChange}>
                        <span>Ny grupp</span>
                      </SubLink>
                    </MenuItem>
                    {user.siteAdmin && (
                      <MenuItem>
                        <SubLink to={COMPANY + url.ADMIN + url.ADMIN_SITE} onClick={this.onRouteChange}>
                          <span>Webbplats</span>
                        </SubLink>
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
              </MenuItem>
            )}
            {!user.superAdmin && (
              <MenuItem $mobile>
                <MenuLink to={COMPANY + url.KUNDSERVICE} onClick={this.onRouteChange}>
                  <span>Kundtjänst</span>
                </MenuLink>
              </MenuItem>
            )}
            {this.props.user.active && (
              <>
                <MenuItem $mobile>
                  <MenuLink to={COMPANY + url.INST_MY_PROFILE} onClick={this.onRouteChange}>
                    <span>Min profil</span>
                  </MenuLink>
                </MenuItem>
                <MenuItem $mobile>
                  <MenuLink to="/" onClick={this.logout}>
                    <span>Logga ut</span>
                  </MenuLink>
                </MenuItem>
              </>
            )}
          </Menu>
        </div>
      </SideBar>
    )
  }
}
