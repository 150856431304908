import { memoize } from "lodash"
import { flow, join, map, sortBy } from "lodash/fp"
import calculateScore from "_root/utils/calculateScore"

export const memoizedCalculateScore = memoize(calculateScore, ({ publicationChannels, hasOther }) => {
  const keyParts = []

  const pubChannelKeyPart = flow(sortBy("sort"), map("id"), join("_"))(publicationChannels)

  if (pubChannelKeyPart) keyParts.push(pubChannelKeyPart)

  keyParts.push(`other:${hasOther ? "t" : "f"}`)

  return keyParts.join("_")
})
