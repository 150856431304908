import { map } from 'lodash/fp'

const calculateScore = ({ publicationChannels, hasOther }) => {
  let score = 0
  map(pc => {
    switch (pc.title) { 
      case "Arbetsförmedlingen - genom Jobbet.se":
        score += 15
        break
      case "Facebook":
        score += 20
        break
      case "Instagram":
        score += 20
        break
      case "Linkedin":
        score += 40
        break
      case "Jobbet.se":
        score += 20
        break
      case "Snapchat":
        score += 15
        break
      case "Tiktok":
        score += 15
        break
      default:
        break
    }
  })(publicationChannels.filter(p => p !== null))

  if ( hasOther ) score += 15

  return score // Max should be 160 degrees
}

export default calculateScore
