import React from "react"
import { gql } from "@apollo/client"
import { RecruitmentState, REC_STATS_ID } from "_root/constants"

export default function withUpdateCacheOnBroadcast(updateOptions) {
  const mutationQueryMap = Object.keys(updateOptions).reduce((map, queryName) => {
    const value = updateOptions[queryName]
    if (!value) {
      return map
    }
    const mutationNames = Array.isArray(value) ? value : [value]
    for (let i = 0; i < mutationNames.length; i++) {
      const mutation = mutationNames[i]
      if (!(mutation in map)) {
        map[mutation] = [queryName]
      } else {
        map[mutation].push(queryName)
      }
    }
    return map
  }, {})

  return WrappedComponent => {
    return class extends React.PureComponent {
      constructor(props) {
        super(props)
        this.displayName = "UpdateCacheOnBroadcast"
      }

      componentDidMount = () => {
        window.addEventListener("message", this.handleWindowMessage.bind(this))
      }

      componentWillUnmount() {
        window.removeEventListener("message", this.handleWindowMessage)
      }

      handleWindowMessage(event) {
        const {
          data: { operation },
        } = event

        if (!operation || !operation.operationName) {
          return
        }

        if (operation.operationName in mutationQueryMap) {
          const queries = mutationQueryMap[operation.operationName]
          for (let i = 0; i < queries.length; i++) {
            const queryName = queries[i]
            if (!this.props.recruitment || !this.props.client) {
              continue
            }
            if (queryName === "RecruitmentStats") {
              const { state } = this.props.recruitment
              const recruitmentStats = {
                __typename: "RecruitmentStats",
                id: REC_STATS_ID,
                countRecruitmentsIsStale: true,
                activeRecruitmentsIsStale: state === RecruitmentState.ACTIVE,
                draftRecruitmentsIsStale: state === RecruitmentState.DRAFT,
                closedRecruitmentsIsStale: state === RecruitmentState.CLOSED,
              }
              this.props.client.writeQuery({
                query: gql`
                  query RecruitmentStats {
                    recruitmentStats {
                      countRecruitmentsIsStale
                      activeRecruitmentsIsStale
                      draftRecruitmentsIsStale
                      closedRecruitmentsIsStale
                    }
                  }
                `,
                data: {
                  recruitmentStats,
                },
              })
            }
          }
        }
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}
